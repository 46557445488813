<template>
    <v-container fluid class="doc-container pa-4 pa-sm-6 pa-md-8">
        <v-row justify="center">
            <v-col cols="12" sm="10" md="8" lg="8" xl="6">
                <v-alert v-model="alert" type="error" dense text class="mb-4">
                    加载文章出错
                </v-alert>
                <v-card class="pa-6" elevation="2">
                    <h1 class="text-h3 font-weight-bold mb-4">{{ title }}</h1>
                    <v-divider class="mb-4"></v-divider>
                    <div class="d-flex align-center mb-6" v-if="author">
                        <v-icon class="mr-2" color="primary">mdi-account</v-icon>
                        <span class="subtitle-1 mr-4">{{ author }}</span>
                        <v-icon class="mr-2" color="primary">mdi-calendar</v-icon>
                        <span class="subtitle-1">{{ publishTime }}</span>
                    </div>
                    <v-divider class="mb-6"></v-divider>
                    <div class="content" v-html="content"></div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
    name: 'Doc',
    data() {
        return {
            title: '',
            author: '',
            publishTime: '',
            content: '',
            alert: false
        }
    },
    created() {
        axios.get(`https://qidafang.com/api/article/${this.$route.params.name}`)
            .then(response => {
                const data = response.data
                if(data) {
                    this.title = data.title
                    this.content = data.content
                    this.author = data.author
                    this.publishTime = moment(data.publishTime).format('YYYY年MM月DD日')
                } else {
                    this.alert = true
                }
            })
            .catch(error => {
                this.alert = true
            })
    },
}
</script>

<style>
.doc-container {
    background-color: #f5f5f5;
    min-height: calc(100vh - 100px); /* 调整为顶部导航栏和页脚的总高度 */
}

.content {
    font-size: 16px;
    line-height: 1.8;
    color: #333;
}

.content p {
    margin-bottom: 1.5rem;
}

.content h2 {
    font-size: 1.75rem;
    font-weight: 600;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
    color: #2c3e50;
}

.content h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #2c3e50;
}

.content ul, .content ol {
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
}

.content li {
    margin-bottom: 0.5rem;
}

.content code {
    font-family: 'Roboto Mono', monospace;
    font-size: 0.9em;
    background-color: #f0f0f0;
    padding: 0.2em 0.4em;
    border-radius: 3px;
    color: #e83e8c;
}

.content pre {
    background-color: #f8f8f8;
    padding: 1rem;
    border-radius: 4px;
    overflow-x: auto;
    margin-bottom: 1.5rem;
}

.content pre code {
    background-color: transparent;
    padding: 0;
    color: inherit;
}

.content img {
    max-width: 100%;
    height: auto;
    margin: 1.5rem 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.content blockquote {
    border-left: 4px solid #3f51b5;
    padding-left: 1rem;
    margin: 1.5rem 0;
    font-style: italic;
    color: #555;
}

.content a {
    color: #3f51b5;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border-color 0.3s ease;
}

.content a:hover {
    border-bottom-color: #3f51b5;
}
</style>
